import './style.scss';

import { adminHeight } from './scripts/_helpers.js';

import smoothScroll from './scripts/smoothScroll.js';

import { gsap } from 'gsap/gsap-core';
import Glide from '@glidejs/glide';


function initHeaderTilt() {
  document.querySelector('#header').addEventListener('mousemove', moveImages);
}
function moveImages(e) {
  const { offsetX, offsetY, target } = e;
  const { clientWidth, clientHeight } = target;

  const xPos = offsetX / clientWidth - 0.5;
  const yPos = offsetY / clientHeight - 0.5;

  const leftImages = gsap.utils.toArray('.hg__left .hg__image');
  const rightImages = gsap.utils.toArray('.hg__right .hg__image');

  const modifier = index => index * 1.5 + 0.5;

  leftImages.forEach((image, index) => {
    gsap.to(image, {
      duration: 1.2,
      x: xPos * 20 * modifier(index),
      y: yPos * 30 * modifier(index),
      rotationX: yPos * 10,
      rotationY: xPos * 40,
      rotationZ: -xPos * 2,
      ease: 'Power3.out',
    });
  });

  rightImages.forEach((image, index) => {
    gsap.to(image, {
      duration: 1.2,
      x: -xPos * 20 * modifier(index),
      y: -yPos * 20 * modifier(index),
      rotationX: yPos * 10,
      rotationY: xPos * 40,
      rotationZ: -xPos * 2,
      ease: 'Power3.out',
    });
  });

}

function brandSlider() {
  const glide = new Glide('.glide', {
    type: 'slider',
    perView: 5,
    gap: '24px',
    bound: true,
    breakpoints: {
      980: {
        perView: 4,
      },
      760: {
        perView: 3,
      },
      560: {
        perView: 2,
      },
      420: {
        perView: 1
      },
    },
  }).mount();
}

function init() {

  adminHeight();
  
  if (document.querySelector('body.home')) {
  
    initHeaderTilt();
    brandSlider();
  }
  
  // smoothScroll();
  
}

// Run on load
window.addEventListener('load', function () {
  init();
});

